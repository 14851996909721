import * as React from 'react';
import classNames from 'classnames/bind';

import styles from './TourDetailsCard.scss';
import { DEFAULT_ICON_SIZE, StyleGuideColorsEnum } from 'common/constants';
import Card from 'design-system/components/Card/Card';
import { useTranslation } from 'react-i18next';
import InfoTable, { InfoTableRowT } from 'design-system/components/InfoTable/InfoTable';
import TruckIcon from 'common/icons/TruckIcon';
import AssetLabelFormatter from 'design-system/components/InfoTable/formatters/AssetLabelFormatter/AssetLabelFormatter';
import TrailerIcon from 'common/icons/TrailerIcon';
import { ApiOrderWaypointT, ApiOrderWaypointTypeT, OrderWaypointTypeEnum } from 'common/utils/api/models';
import { formatTimeInterval } from 'common/utils/time';
import Tooltip, { TooltipPositionEnum, TooltipThemeEnum } from 'design-system/components/Tooltip/Tooltip';
import NumberIcon from 'common/icons/NumberIcon';
import LocationLabel from 'common/components/LocationLabel/LocationLabel';
import { TFunction } from 'i18next';
import TooltipContent, {
    TooltipContentThemeEnum,
} from 'design-system/components/Tooltip/TooltipContent/TooltipContent';
import TimeWindowIcon from 'common/icons/TimeWindowIcon';
import AttentionTextFormatter from 'design-system/components/InfoTable/formatters/AttentionTextFormatter/AttentionTextFormatter';
import { ApiOrderTourT } from 'common/store/order-details/models';
import { checkBrokerAppointmentStatus } from 'common/utils/broker-appointment';

export type PropsT = {
    tour: ApiOrderTourT | null | undefined;
    tourNumber: number;
    className?: string;
    keyboardShortcut?: string;
};

const cx = classNames.bind(styles);

const detailedWaypointTypeSet = new Set<ApiOrderWaypointTypeT>([OrderWaypointTypeEnum.pickupDeliveryWaypoint]);

const renderWaypointName = (t: TFunction, waypoint: ApiOrderWaypointT | null | undefined) => {
    if (!waypoint) {
        return null;
    }

    switch (waypoint.type) {
        default: {
            return t('common:waypoint-details.by-type.pickup-delivery', {
                number: (waypoint?.index || 0) + 1,
            });
        }
    }
};

const renderWaypointStopIcon = (waypoint: ApiOrderWaypointT | null | undefined) => {
    if (!waypoint) {
        return null;
    }

    switch (waypoint.type) {
        case OrderWaypointTypeEnum.pickupDeliveryWaypoint: {
            return <NumberIcon number={(waypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.charcoal} />;
        }
        default: {
            return <NumberIcon number={(waypoint?.index || 0) + 1} fillColor={StyleGuideColorsEnum.gray} />;
        }
    }
};

const TourDetailsCard: React.FC<PropsT> = React.memo((props) => {
    const { tour, tourNumber, keyboardShortcut, className } = props;

    const { t } = useTranslation();

    const truck = tour?.truck || null;
    const trailer = tour?.trailer || null;
    const waypoints = tour?.waypoints || [];

    const brokerWindowAppointmentWarning = (
        <Tooltip
            position={TooltipPositionEnum.topLeft}
            theme={TooltipThemeEnum.black}
            tooltipNode={
                <TooltipContent theme={TooltipContentThemeEnum.black} width={150}>
                    {t('dispatches.details.broke-window-appointment-warning')}
                </TooltipContent>
            }
        >
            {(isShow, childrenClassName) => (
                <TimeWindowIcon
                    className={childrenClassName}
                    fillColor={StyleGuideColorsEnum.transparent}
                    strokeColor={StyleGuideColorsEnum.orange}
                />
            )}
        </Tooltip>
    );

    const getWaypointDetailRows = (waypoint: ApiOrderWaypointT): Array<InfoTableRowT | null> => {
        const isDetailedWaypoint = detailedWaypointTypeSet.has(waypoint?.type);
        if (!isDetailedWaypoint) {
            return [
                {
                    icon: renderWaypointStopIcon(waypoint),
                    name: renderWaypointName(t, waypoint),
                    value: <LocationLabel format="s1_s2_zip_city_country" location={waypoint?.address} />,
                    emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                    isBoldValue: true,
                    rows: [
                        {
                            icon: null,
                            name: t('common:waypoint-details.columns.time-slot'),
                            value: formatTimeInterval(
                                waypoint?.correctedDateTimeFrom || waypoint?.originalDateTimeFrom,
                                waypoint?.correctedDateTimeTo || waypoint?.originalDateTimeTo,
                            ),
                            emptyValue: t('common:info-table.placeholders.not-specified'),
                            isBoldValue: true,
                        },
                    ],
                },
            ];
        }

        const brokerAppointmentStatus = checkBrokerAppointmentStatus({
            shouldBrokerWindowAppointment: waypoint?.appointmentByBroker,
            originalDateTimeFrom: waypoint?.originalDateTimeFrom,
            originalDateTimeTo: waypoint?.originalDateTimeTo,
            correctedDateTimeFrom: waypoint?.correctedDateTimeFrom,
            correctedDateTimeTo: waypoint?.correctedDateTimeTo,
        });

        return [
            {
                icon: renderWaypointStopIcon(waypoint),
                name: renderWaypointName(t, waypoint),
                value: <LocationLabel format="s1_s2_zip_city_country" location={waypoint?.address} />,
                emptyValue: t('common:info-table.placeholders.carrier-not-assigned'),
                isBoldValue: true,
                rows: [
                    ...(brokerAppointmentStatus.shouldShowCorrectedDateTime
                        ? [
                              {
                                  icon: null,
                                  name: t('common:waypoint-details.columns.original-time-slot'),
                                  value: formatTimeInterval(
                                      waypoint?.originalDateTimeFrom,
                                      waypoint?.originalDateTimeTo,
                                  ),
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  hasBottomBorder: true,
                              },
                              {
                                  icon: brokerAppointmentStatus.shouldBrokerWindowAppointment
                                      ? brokerWindowAppointmentWarning
                                      : null,
                                  name: (
                                      <AttentionTextFormatter
                                          isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}
                                      >
                                          {t('common:waypoint-details.columns.corrected-time-slot')}
                                      </AttentionTextFormatter>
                                  ),
                                  value: (
                                      <AttentionTextFormatter
                                          isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}
                                      >
                                          {formatTimeInterval(
                                              waypoint?.correctedDateTimeFrom,
                                              waypoint?.correctedDateTimeTo,
                                          )}
                                      </AttentionTextFormatter>
                                  ),
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  hasBottomBorder: true,
                              },
                          ]
                        : [
                              {
                                  icon: brokerAppointmentStatus.shouldBrokerWindowAppointment
                                      ? brokerWindowAppointmentWarning
                                      : null,
                                  name: (
                                      <AttentionTextFormatter
                                          isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}
                                      >
                                          {t('common:waypoint-details.columns.time-slot')}
                                      </AttentionTextFormatter>
                                  ),
                                  value: (
                                      <AttentionTextFormatter
                                          isActive={brokerAppointmentStatus.shouldBrokerWindowAppointment}
                                      >
                                          {formatTimeInterval(
                                              waypoint?.correctedDateTimeFrom || waypoint?.originalDateTimeFrom,
                                              waypoint?.correctedDateTimeTo || waypoint?.originalDateTimeTo,
                                          )}
                                      </AttentionTextFormatter>
                                  ),
                                  emptyValue: t('common:info-table.placeholders.not-specified'),
                                  isBoldValue: true,
                                  hasBottomBorder: true,
                              },
                          ]),
                    {
                        icon: null,
                        name: t('common:waypoint-details.columns.company-name'),
                        value: waypoint?.contact?.companyName,
                        emptyValue: t('common:info-table.placeholders.not-specified'),
                        isBoldValue: true,
                        testSelector: 'contact-company-name',
                        hasBottomBorder: true,
                    },
                    {
                        icon: null,
                        name: t('common:waypoint-details.columns.contact-name'),
                        value: waypoint?.contact?.fullName,
                        emptyValue: t('common:info-table.placeholders.not-specified'),
                        isBoldValue: true,
                        hasBottomBorder: true,
                    },
                    {
                        icon: null,
                        name: t('common:waypoint-details.columns.contact-phone'),
                        value: waypoint?.contact?.phone,
                        emptyValue: t('common:info-table.placeholders.not-specified'),
                        isBoldValue: true,
                        hasBottomBorder: true,
                    },
                    {
                        icon: null,
                        name: t('common:waypoint-details.columns.contact-email'),
                        value: waypoint?.contact?.email,
                        emptyValue: t('common:info-table.placeholders.not-specified'),
                        isBoldValue: true,
                        hasBottomBorder: true,
                    },
                    {
                        icon: null,
                        name: t('common:waypoint-details.columns.comments'),
                        value: waypoint?.contact?.comment,
                        emptyValue: t('common:info-table.placeholders.not-specified'),
                        isBoldValue: true,
                    },
                ],
            },
        ];
    };

    const assetsDetails: Array<InfoTableRowT | null> = [
        {
            icon: (
                <TruckIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:waypoint-details.columns.truck'),
            value: truck ? (
                <AssetLabelFormatter model={truck?.dictTruckInfo?.model} plateNumber={truck?.plateNumber} />
            ) : null,
            emptyValue: t('common:info-table.placeholders.truck-not-assigned'),
            isBoldValue: true,
        },
        {
            icon: (
                <TrailerIcon
                    size={DEFAULT_ICON_SIZE}
                    strokeColor={StyleGuideColorsEnum.gray}
                    fillColor={StyleGuideColorsEnum.light}
                />
            ),
            name: t('common:waypoint-details.columns.trailer'),
            value: trailer ? (
                <AssetLabelFormatter model={trailer?.dictTrailerInfo?.model} plateNumber={trailer?.plateNumber} />
            ) : null,
            emptyValue: t('common:info-table.placeholders.trailer-not-assigned'),
            isBoldValue: true,
        },
    ];

    return (
        <>
            <Card
                titleNode={t('common:order-details.tour-details.title', {
                    number: tourNumber,
                })}
                className={className}
                hasHeaderBottomBorder
                keyboardShortcut={keyboardShortcut}
            >
                <div className={cx('content')}>
                    <InfoTable shouldRenderIcons rows={assetsDetails} className={cx('table', 'table--assets')} />
                    {waypoints.map((waypoint, index) => {
                        return (
                            <InfoTable
                                key={`${index}-${waypoint.id}`}
                                shouldRenderIcons
                                rows={getWaypointDetailRows(waypoint)}
                                className={cx('table', 'table--waypoint')}
                            />
                        );
                    })}
                </div>
            </Card>
        </>
    );
});

export default TourDetailsCard;
